import axios from 'axios';
import { clear_local_storage, ErrorNotification } from '../Utils/data';

const instance = axios.create({
  baseURL: 'https://front-api.zeelog.com/api/',
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const statusCode = error.response.data.error.statusCode;
      if (statusCode === 401) {
        const isLoginEndpoint = error.config.url.includes('/login');
        if (!isLoginEndpoint) {
          clear_local_storage();
          ErrorNotification({
            status: error.response && error.response.status,
            message: error.response.data.error.message,
          });
          const audio = new Audio('/bmw_warning.mp3');
          audio.play();
        }
      }
    }
    return Promise.reject(error);
  },
);

const token: string | null = localStorage.getItem('token');

if (token) {
  instance.defaults.headers.common['Authorization'] = token;
}

export default instance;
