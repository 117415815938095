import React from 'react';
import {Avatar, Button, Modal, Space, Spin, Table, Tag, Tooltip} from "antd";
import {supports} from "../../../../../API/TT_ELD/supports";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {useSelector} from "react-redux";
import LogoDark from "../../../../assets/logo/logoDark.svg";
import LogoLight from "../../../../assets/logo/logoLight.svg";
import EVO from "../../../../assets/logo/evodark.svg";

const {confirm} = Modal;

type numStr = string | number

interface companySource {
    no: numStr,
    img: {whichservice: string } ;
    name: numStr,
    address: numStr,
    usdot: numStr
    phone: numStr
    action: { userCompanyId: numStr };
    key: React.Key;

}

const SupportUserTable = ({
                              company = [],
                              dispatcherId,
                              isLoading,
                              isFetching,
                              refetch
                          }: { company: any, dispatcherId: string | number, isLoading: boolean | undefined, isFetching: any , refetch():void}) => {
    const { theme } = useSelector((state: any) => state.global);

    const columns: object[] = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
        },
        {
            title: 'Logo',
            dataIndex: 'img',
            key: 'img',
            width:50,
            render: (val :{whichservice: string | null}) => (
                <>
                    {val.whichservice && <Tooltip title={val.whichservice === 'zeelog' ? 'Zeelog' : 'EVO ELD'} placement="top">
                        <Avatar shape="square" size='small'
                                src={val.whichservice === 'zeelog' ? LogoLight : EVO}/>
                    </Tooltip>}
                </>

            ),
        },
        {
            title: 'Company',
            dataIndex: 'name',
            key: 'name',
        }, {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'USDOT',
            dataIndex: 'usdot',
            key: 'usdot',
        },
        {
            title: 'Phone number',
            dataIndex: 'phone',
            key: 'phone',
        },

        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            render: ({userCompanyId}: { userCompanyId: string | number,  }) => {
                const showConfirm = () => {
                    confirm({
                        title: 'Approval',
                        icon: <ExclamationCircleFilled rev={undefined}/>,
                        content: 'Do you want to delete this model ?',
                        onOk: async () => {
                            return new Promise(async (resolve, reject) => {
                                setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                                supports.deleteCompany(userCompanyId)
                                refetch()

                            }).catch(() => {
                                {
                                    refetch()
                                }
                            });
                        },

                    });
                };
                return (
                    <Space>
                        {/*<Link to={`${userCompanyId}`}><Button>Edit</Button></Link>*/}
                        <Button type='primary' danger onClick={showConfirm}>
                            Delete
                        </Button>
                    </Space>)
            }

        },

    ]

    return (
        <div style={{marginTop: '10px'}}>
            <Spin size='large' spinning={isLoading || isFetching}>
                <Table style={{margin:'15px 0 0 0'}} dataSource={company?.companies?.map((u: any, i: number): companySource => {
                    const obj: companySource = {
                        no: i + 1,
                        img: {whichservice: u?.whichservice} ,
                        name: u.name,
                        address: u.address,
                        usdot: u.usdot,
                        phone: u.phone,
                        action: {userCompanyId: u.userCompanyId},
                        key: u.userCompanyId
                    }
                    return obj
                })} columns={columns}/>
            </Spin>
        </div>
    );
};

export default SupportUserTable;