import type { FC } from "react";

import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Drawer,
  Dropdown,
  Layout,
  theme as antTheme,
  Tooltip,
} from "antd";
import React, {createElement, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Avator from "../../assets/header/avator.jpeg";
import Color from "../../assets/header/color.png";
import { ReactComponent as EnUsSvg } from "../../assets/header/en_US.svg";
import { ReactComponent as LanguageSvg } from "../../assets/header/language.svg";
import { ReactComponent as MoonSvg } from "../../assets/header/moon.svg";
import { ReactComponent as SunSvg } from "../../assets/header/sun.svg";
import { ReactComponent as ZhCnSvg } from "../../assets/header/zh_CN.svg";
import LogoLight from "../../assets/logo/logoLight.svg";
import LogoDark from "../../assets/logo/logoDark.svg";
import { LocaleFormatter, useLocale } from "../../locales";
import { setGlobalState } from "../../stores/global.store";
import { setUserItem } from "../../stores/user.store";
import { logoutAsync } from "../../stores/user.action";
import HeaderNoticeComponent from "./notice";
import {
  clear_local_storage,
} from "../../../Utils/data";
import BasicColor from "./basicColor";



const { Header } = Layout;

interface HeaderProps {
  collapsed: boolean;
  toggle: () => void;
}

type Action = "userInfo" | "userSetting" | "logout";
const HeaderComponent: FC<HeaderProps> = ({ collapsed, toggle }) => {

  const { logged, locale, device } = useSelector((state: any) => state.user);
  const { theme } = useSelector((state: any) => state.global);

  const navigate = useNavigate();
  const token = antTheme.useToken();
  const dispatch: any = useDispatch();
  const { formatMessage } = useLocale();

  const onActionClick = async (action: Action) => {
    switch (action) {
      case "userInfo":
        return;
      case "userSetting":
        return;
      case "logout":
        const res = Boolean(await dispatch(logoutAsync()));
        res && navigate("/login");
        return;
    }
  };

  const toLogin = () => {
    navigate("/login");
    clear_local_storage();
  };

  const selectLocale = ({ key }: { key: any }) => {
    dispatch(setUserItem({ locale: key }));
    localStorage.setItem("locale", key);
  };

  const onChangeTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    localStorage.setItem("theme", newTheme);
    dispatch(
      setGlobalState({
        theme: newTheme,
      })
    );
  };

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Header
        className={
          theme === "dark" ? `layout-page-header-light` : `layout-page-header`
        }
        style={{
          display: "flex",
          backgroundColor: "#0492F6",
        }}
      >
        {device !== "MOBILE" && (
          <div className="logo" style={{ width: collapsed ? 80 : 200 }}>
            {theme === "dark" ? (
              <>
                {collapsed ? (
                  <img
                    src={LogoLight}
                    alt=""
                    style={{ marginRight: collapsed ? "2px" : "20px" }}
                  />
                ) : (
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={LogoLight}
                      alt=""
                      style={{ marginRight: collapsed ? "2px" : "8px" }}
                    />
                    <span style={{color:'#ffffff', fontWeight: "600", fontSize: "20px" }}>
                      Zeelog
                    </span>
                  </span>
                )}
              </>
            ) : (
              <>
                {collapsed ? (
                  <img
                    src={LogoLight}
                    alt=""
                    style={{ marginRight: collapsed ? "2px" : "20px" }}
                  />
                ) : (
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={LogoLight}
                      alt=""
                      style={{ marginRight: collapsed ? "2px" : "8px" }}
                    />
                    <span style={{color:'#ffffff', fontWeight: "600", fontSize: "20px" }}>
                      Zeelog
                    </span>
                  </span>
                )}
              </>
            )}
          </div>
        )}
        <div className="layout-page-header-main">
          <div onClick={toggle}>
            <span style={{ cursor: "pointer" }} id="sidebar-trigger">
              {collapsed ? (
                <MenuUnfoldOutlined
                  style={{color:'#ffffff', fontSize: "20px" }}
                  rev={undefined}
                />
              ) : (
                <MenuFoldOutlined
                  style={{ color:'#ffffff', fontSize: "20px" }}
                  rev={undefined}
                />
              )}
            </span>
          </div>

          <div className="actions">
            {/* <Tooltip title="Choose color">
              <img
                style={{ width: "22px", cursor: "pointer" }}
                onClick={showDrawer}
                src={Color}
              />
            </Tooltip> */}
            {/* <Drawer
              title="Basic Colors"
              placement="right"
              onClose={onClose}
              open={open}
            >
            <BasicColor/>
            </Drawer> */}
            <Tooltip
              title={formatMessage({
                id:
                  theme === "dark"
                    ? "gloabal.tips.theme.darkTooltip"
                    : "gloabal.tips.theme.lightTooltip",
              })}
            >
              <span style={{  cursor: "pointer" }}>
                {createElement(theme === "dark" ? MoonSvg : SunSvg, {
                  onClick: onChangeTheme,
                })}
              </span>
            </Tooltip>

            <HeaderNoticeComponent />
            {/* <Dropdown
              menu={{
                onClick: (info) => selectLocale(info),
                items: [
                  {
                    key: "zh_CN",
                    icon: <ZhCnSvg />,
                    disabled: locale === "zh_CN",
                    label: "简体中文",
                  },
                  {
                    key: "en_US",
                    icon: <EnUsSvg />,
                    disabled: locale === "en_US",
                    label: "English",
                  },
                ],
              }}
            >
              <span style={{ color:'#ffffff', cursor: "pointer" }}>
                <LanguageSvg id="language-change" />
              </span>
            </Dropdown> */}
            {logged ? (
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "1",
                      icon: <UserOutlined rev={undefined} />,
                      label: (
                        <span onClick={() => navigate("/dashboard")}>
                          <LocaleFormatter id="header.avator.account" />
                        </span>
                      ),
                    },
                    {
                      key: "2",
                      icon: <LogoutOutlined rev={undefined} />,
                      label: (
                        <span onClick={() => onActionClick("logout")}>
                          <LocaleFormatter id="header.avator.logout" />
                        </span>
                      ),
                    },
                  ],
                }}
              >
                <span className="user-action">
                  <img src={Avator} className="user-avator" alt="avator" />
                </span>
              </Dropdown>
            ) : (
              <>
                <span style={{ color:'#ffffff',cursor: "pointer" }} onClick={toLogin}>
                  {formatMessage({ id: "gloabal.tips.login" })}
                </span>
              </>
            )}
          </div>
        </div>
      </Header>
    </>
  );
};

export default HeaderComponent;
