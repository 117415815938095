import axios from 'axios';
import { clear_local_storage } from '../Utils/data';

const token: string | null = localStorage.getItem('token');

const apiRead = axios.create({
  baseURL: 'https://queue.evoeld.com/api/',
});

apiRead.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.data.error.statusCode === 401) {
        clear_local_storage();
      }
    }
    return Promise.reject(error);
  },
);
if (token) {
  apiRead.defaults.headers.common['Authorization'] = ` ${token}`;
}

export default apiRead;
